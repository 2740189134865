import React from 'react';

import RegoACT from '@/images/regos/rego-act.svg';
import RegoNSW from '@/images/regos/rego-nsw.svg';
import RegoNT from '@/images/regos/rego-nt.svg';
import RegoQLD from '@/images/regos/rego-qld.svg';
import RegoSA from '@/images/regos/rego-sa.svg';
import RegoTAS from '@/images/regos/rego-tas.svg';
import RegoVIC from '@/images/regos/rego-vic.svg';
import RegoWA from '@/images/regos/rego-wa.svg';

export const getStatePlate = (state: string) => {
  switch (state) {
    case 'ACT':
      return <RegoACT />;
    case 'NSW':
      return <RegoNSW />;
    case 'NT':
      return <RegoNT />;
    case 'QLD':
      return <RegoQLD />;
    case 'SA':
      return <RegoSA />;
    case 'TAS':
      return <RegoTAS />;
    case 'VIC':
      return <RegoVIC />;
    case 'WA':
      return <RegoWA />;
    default:
      return null;
  }
};
