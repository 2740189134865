import { FormikErrors, FormikTouched } from 'formik';

/**
 * Extracts error from formik error using Warlock multi step wrapper.
 * @param name of the the field
 * @param touched formik touched object
 * @param errors formik error object
 * @param label of the field
 * @returns error string
 */
export function extractErrors<T = string>(
  name: keyof T,
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  label?: string,
) {
  const error = errors[name];
  if (!touched[name] || !error) {
    return undefined;
  }

  const fieldError = Array.isArray(error)
    ? String(error[0])
    : typeof error === 'object'
    ? Object(error)
    : String(error);

  if (label && typeof fieldError === 'string') {
    return fieldError.replace(name.toString(), label);
  }

  return fieldError;
}

/**
 * Converts given array into SelectFieldItem option
 * @param arr
 * @returns option object
 */
export function convertToOption(arr: (string | number)[]) {
  return arr.map((a) => ({ value: a, title: typeof a === 'string' ? a : a.toString() }));
}

/**
 * Regex valdiate to validate email on warlock form
 */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
